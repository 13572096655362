import * as React from 'react'
import styles from './blog.module.css'
import Layout from '../components/Layout'
import { Link, StaticQuery, graphql } from 'gatsby'
import BlogItem from '../components/Blog/Item'
import Helmet from 'react-helmet'

interface PostsQuery {
    data: {
        allGhostPost: {
            edges: [
                {
                    node: {
                        id: string
                        slug: string
                        title: string
                        published_at: string
                        excerpt: string
                    }
                }
            ]
        }
        site: {
            siteMetadata: {
                title: string
            }
        }
    }
}

export interface PostNode {
    node: {
        id: string
        slug: string
        title: string
        published_at: string
        excerpt: string
    }
}

export default class extends React.Component<PostsQuery, {}> {
    constructor(props: any, context: any) {
        super(props, context)
    }
    public render() {
        return (
            <Layout>
                <Helmet>
                    <title>
                        {this.props.data.site.siteMetadata.title} - Blog
                    </title>
                </Helmet>
                <div className={styles.blogListing}>
                    <h1>Blog</h1>
                    <p>
                        These thoughts were once in my head, now they're on the
                        internet.
                    </p>
                    <div className={styles.blogList}>
                        <div>
                            {this.props.data.allGhostPost.edges.map(
                                (post, i) => {
                                    return <BlogItem key={i} node={post.node} />
                                }
                            )}
                        </div>
                    </div>
                    <div className={styles.back}>
                        <Link to="/">
                            <span>Home</span>.
                        </Link>
                    </div>
                </div>
            </Layout>
        )
    }
}

export const postsQuery = graphql`
    {
        allGhostPost(sort: { order: DESC, fields: [published_at] }) {
            edges {
                node {
                    id
                    slug
                    title
                    published_at(formatString: "MMMM DD, YYYY")
                    excerpt
                }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`
