import * as React from 'react'
import styles from './Item.module.css'
import { Link } from 'gatsby'
import { PostNode } from '../../pages/blog'
import PostMeta from './Post/Meta'

export default class BlogItem extends React.Component<PostNode, {}> {
    public render() {
        return (
            <div className={styles.blogItem}>
                <h2 className={styles.blogTitle}>
                    <Link
                        to={'/' + this.props.node.slug + '/'}
                        className={styles.readMore}
                    >
                        {this.props.node.title}
                    </Link>
                </h2>
                <div className={styles.postMeta}>
                    <PostMeta node={this.props.node} />
                </div>
                <p className={styles.blogExcept}>{this.props.node.excerpt}</p>
                <Link
                    to={'/' + this.props.node.slug + '/'}
                    className={styles.readMore}
                >
                    <span>Read more</span>.
                </Link>
            </div>
        )
    }
}
